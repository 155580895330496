// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-contactez-nous-js": () => import("./../src/pages/contactez-nous.js" /* webpackChunkName: "component---src-pages-contactez-nous-js" */),
  "component---src-pages-devenez-partenaire-js": () => import("./../src/pages/devenez-partenaire.js" /* webpackChunkName: "component---src-pages-devenez-partenaire-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-rayonnement-js": () => import("./../src/pages/rayonnement.js" /* webpackChunkName: "component---src-pages-rayonnement-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

